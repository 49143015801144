import { BaseMap } from 'components/BaseMap/BaseMap';
import Logo from 'images/logo-real.png';
import LocationIcon from 'images/real-location.svg';
import React from 'react';
import { Form } from 'sections/Form/Form';
import { mapStyles } from 'shared/mapStylesContact';
import {
    Adress,
    AdressBox,
    LogoImage,
    MapBox,
    MapWrapper,
    MarkdownText,
    StyledLink,
    StyledTitle,
    Text,
    TextLight,
    Wrapper,
} from './FormSection.styled';

export interface FormSectionProps {
    contactPhones: { phoneNumber: string }[];
    emailAddresses: { emailAddress: string }[];
    mapPinLat: string;
    mapPinLng: string;
    salesOfficeAddress: string;
}

export const FormSection = ({
    contactPhones,
    emailAddresses,
    mapPinLat,
    mapPinLng,
    salesOfficeAddress,
}: FormSectionProps) => {
    const markers = [
        {
            title: 'Lokalizacja',
            position: {
                lat: Number(mapPinLat),
                lng: Number(mapPinLng),
            },
            iconUrl: LocationIcon,
        },
    ];
    return (
        <Wrapper>
            <Form />
            <MapBox>
                <MapWrapper>
                    <BaseMap
                        mapPosition={{
                            lat: Number(mapPinLat),
                            lng: Number(mapPinLng),
                        }}
                        zoom={18}
                        markers={markers}
                        mapStyles={mapStyles}
                    />
                </MapWrapper>
                <AdressBox>
                    <StyledTitle>Biuro sprzedaży</StyledTitle>
                    <Adress>
                        <MarkdownText markdown={salesOfficeAddress} />
                        <Text>
                            <TextLight> e-mail: </TextLight>
                            {emailAddresses?.map((item) => (
                                <StyledLink
                                    href={'mailto:' + item?.emailAddress}
                                >
                                    {item?.emailAddress} <br />
                                </StyledLink>
                            ))}
                            <TextLight> tel: </TextLight>
                            {contactPhones?.map((item) => (
                                <StyledLink href={'tel:' + item?.phoneNumber}>
                                    {item?.phoneNumber} <br />
                                </StyledLink>
                            ))}
                        </Text>
                    </Adress>
                    <LogoImage src={Logo} />
                </AdressBox>
            </MapBox>
        </Wrapper>
    );
};
