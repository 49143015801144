import { UniversalHero } from 'components/UniversalHero/UniversalHero';
import HeroBg from 'images/kontakt.png';
import Layout from 'layout/index';
import * as React from 'react';
import { FormSection } from 'sections/FormSection/FormSection';
import { getDisabledPages } from 'utils/utils';
import { NotFoundPageInfo } from 'sections/NotFoundPageInfo/NotFoundPageInfo';

interface ContactPageProps {
    location: {
        pathname: string;
    };
    pageContext: any;
}

export default ({ pageContext, location }: ContactPageProps) => {
    const investmenContactData =
        pageContext?.data?.wpInvestment?.investment?.contactPage;

    const pageTitle = `${pageContext?.title} - ${investmenContactData?.title}`;

    return (
        <Layout
            displayPartnersPage={
                pageContext?.data?.wpInvestment?.investment?.enablePartnersPage
            }
            headerData={pageContext?.headerData}
            investmentSlug={pageContext?.slug}
            pageTitle={pageTitle}
            location={location}
            investmentDisabledPages={getDisabledPages(
                pageContext?.data?.wpInvestment?.investment,
            )}
        >
            <>
                <UniversalHero
                    background={HeroBg}
                    text={investmenContactData?.description}
                    title={investmenContactData?.title}
                />
                <FormSection
                    contactPhones={investmenContactData?.contactPhones}
                    emailAddresses={investmenContactData?.emailAddresses}
                    mapPinLat={investmenContactData?.mapPinLat}
                    mapPinLng={investmenContactData?.mapPinLng}
                    salesOfficeAddress={
                        investmenContactData?.salesOfficeAddress
                    }
                />
            </>
        </Layout>
    );
};
