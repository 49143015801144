import { Markdown } from 'components/Markdown/Markdown';
import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { H4 } from 'styles/Typography.styled';

export const Wrapper = styled.div`
    padding: 160px 136px;
    display: flex;
    gap: 100px;

    ${mediaQueries.md} {
        padding: 80px 44px;
    }

    ${mediaQueries.sm} {
        padding: 60px 24px;
    }
`;

export const MapBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 26px;

    ${mediaQueries.sm} {
        display: none;
    }
`;

export const MapWrapper = styled.div`
    width: 100%;
    height: 300px;
    padding-bottom: 48px;
`;

export const AdressBox = styled.div``;

export const Adress = styled.div`
    display: grid;
    gap: 0 72px;
    grid-template-columns: 1fr 1fr;
`;

export const Text = styled.p(
    ({ theme: { colors, fontSizes, fontWeight } }) =>
        css`
            color: ${colors.neutral900};
            font-size: ${fontSizes.f16};
            font-weight: ${fontWeight.medium};
            line-height: 28px;
        `,
);

export const MarkdownText = styled(Markdown)(
    ({ theme: { colors, fontSizes, fontWeight } }) =>
        css`
            color: ${colors.neutral900};
            font-size: ${fontSizes.f16};
            font-weight: ${fontWeight.medium};
            line-height: 28px;
        `,
);

export const TextLight = styled.span(
    ({ theme: { colors } }) =>
        css`
            color: ${colors.neutral300};
        `,
);

export const StyledTitle = styled(H4)(
    ({ theme: { colors } }) =>
        css`
            color: ${colors.neutral900};
            padding-bottom: 16px;
        `,
);

export const StyledLink = styled.a(
    ({ theme: { colors } }) =>
        css`
            color: ${colors.neutral900};

            &:hover {
                color: ${colors.primaryBlue500};
            }
        `,
);

export const LogoImage = styled.img`
    margin-top: 24px;
    max-width: 50%;
`;
