import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { mediaQueries } from 'shared/breakpoints';

export const Breadcrumb = styled.div(
    ({ theme: { fontSizes, fontWeight, letterSpacing } }) =>
        css`
            display: flex;
            font-size: ${fontSizes.f16};
            font-weight: ${fontWeight.medium};
            letter-spacing: ${letterSpacing.s};
            line-height: 28px;

            ${mediaQueries.xs} {
                display: none;
            }
        `,
);

export const Separator = styled.span<{ isWhite?: boolean }>(
    ({ theme: { colors }, isWhite }) =>
        css`
            color: ${isWhite ? colors.white : colors.neutral900};
            padding: 0 8px;
        `,
);

export const LinkWrapper = styled(Link)<{ isWhite?: boolean }>(
    ({ theme: { colors }, isWhite }) =>
        css`
            color: ${isWhite ? colors.white : colors.neutral900};
            text-decoration: none;

            :hover {
                color: ${isWhite
                    ? colors.primaryBlue300
                    : colors.primaryBlue500};
            }
        `,
);

export const BreadcrumbText = styled.p<{ isWhite?: boolean }>(
    ({ theme: { colors }, isWhite }) => css`
        color: ${isWhite ? colors.white : colors.paleSky};
        text-transform: capitalize;
    `,
);
