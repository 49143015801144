import styled, { css } from 'styled-components';
import { mediaQueries } from 'shared/breakpoints';
import { H1, HeroText } from 'styles/Typography.styled';

export const UniversalHeroWrapper = styled.div(
    ({ theme: { colors } }) =>
        css`
            background-color: ${colors.primaryBlue50};
            background-image: url('/static/images/lokalizacja_bg.png');
            background-position: right;
            background-repeat: no-repeat;
            background-size: contain;
            display: flex;
            flex-direction: column;
            max-width: 100%;
            padding: 80px 136px;

            ${mediaQueries.md} {
                padding: 72px 80px;
            }

            ${mediaQueries.sm} {
                padding: 48px 28px;
            }

            ${mediaQueries.xs} {
                align-items: center;
                background-image: none !important;
                padding: 40px 16px;
                text-align: center;
            }
        `,
);

export const HeroTitle = styled(H1)`
    margin-top: 40px;
    margin-bottom: 24px;

    ${mediaQueries.xs} {
        margin-top: 0;
        margin-bottom: 16px;
    }
`;

export const StyledHeroText = styled(HeroText)(
    ({ theme: { fontSizes } }) =>
        css`
            font-size: ${fontSizes.f18};
        `,
);
