import {
    Breadcrumb,
    BreadcrumbText,
    LinkWrapper,
    Separator,
} from './Breadcrumbs.styled';
import React from 'react';

interface BreadcrumbsProps {
    className?: string;
    isWhite?: boolean;
    subText?: string;
    text: string;
    customText?: string;
}

export const Breadcrumbs = ({
    text,
    className,
    isWhite,
    subText,
    customText,
}: BreadcrumbsProps) => {
    return (
        <Breadcrumb className={className}>
            <LinkWrapper isWhite={isWhite} to={'/'}>
                Strona główna
            </LinkWrapper>
            <Separator isWhite={isWhite}>/</Separator>
            <BreadcrumbText isWhite={isWhite}>
                {customText ?? text}
            </BreadcrumbText>
            {subText && (
                <>
                    <Separator isWhite={isWhite}>/</Separator>
                    <BreadcrumbText isWhite={isWhite}>{subText}</BreadcrumbText>
                </>
            )}
        </Breadcrumb>
    );
};
