import { Breadcrumbs } from 'components/_layout/Breadcrumbs/Breadcrumbs';
import React from 'react';
import {
    HeroTitle,
    StyledHeroText,
    UniversalHeroWrapper,
} from './UniversalHero.styled';

interface UniversalHeroProps {
    background: string;
    subPageTitle?: string;
    text: string;
    title: string;
    customBreadcrumbsText?: string;
}

export const UniversalHero = ({
    subPageTitle,
    background,
    text,
    title,
    customBreadcrumbsText,
}: UniversalHeroProps) => {
    return (
        <UniversalHeroWrapper style={{ backgroundImage: `url(${background})` }}>
            <Breadcrumbs
                customText={customBreadcrumbsText}
                subText={subPageTitle}
                text={title}
            />
            <HeroTitle>{subPageTitle ?? title}</HeroTitle>
            <StyledHeroText>{text}</StyledHeroText>
        </UniversalHeroWrapper>
    );
};
