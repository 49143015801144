import Location from 'images/location-icon.svg';

export const mapStyles = [
    {
        featureType: 'all',
        elementType: 'labels.text.fill',
        stylers: [{ saturation: 36 }, { color: '#333333' }, { lightness: 40 }],
    },
    {
        featureType: 'all',
        elementType: 'labels.text.stroke',
        stylers: [
            { visibility: 'on' },
            { color: '#ffffff' },
            { lightness: 16 },
        ],
    },
    {
        featureType: 'all',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'administrative',
        elementType: 'all',
        stylers: [{ visibility: 'on' }],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [{ weight: 1.2 }, { visibility: 'on' }, { color: '#7c4848' }],
    },
    {
        featureType: 'landscape.man_made',
        elementType: 'all',
        stylers: [{ hue: '#ff0000' }, { saturation: '-100' }],
    },
    {
        featureType: 'landscape.man_made',
        elementType: 'geometry.stroke',
        stylers: [{ visibility: 'on' }, { color: '#e4e6e7' }],
    },
    {
        featureType: 'landscape.natural',
        elementType: 'all',
        stylers: [{ visibility: 'on' }, { color: '#f1f3f3' }],
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{ lightness: 21 }, { color: '#f1f3f3' }],
    },
    {
        featureType: 'poi.medical',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [{ lightness: 21 }],
    },
    {
        featureType: 'poi.school',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'on' }],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [{ color: '#f8fafc' }, { lightness: 17 }],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
            { color: '#ebf0f1' },
            { lightness: 29 },
            { weight: 0.2 },
            { visibility: 'on' },
        ],
    },
    {
        featureType: 'road.highway.controlled_access',
        elementType: 'geometry.fill',
        stylers: [{ color: '#f8fafc' }, { visibility: 'on' }],
    },
    {
        featureType: 'road.highway.controlled_access',
        elementType: 'geometry.stroke',
        stylers: [{ visibility: 'on' }, { color: '#ebf0f1' }],
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [{ color: '#ffffff' }, { lightness: 18 }],
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry.fill',
        stylers: [{ color: '#f8fafc' }, { visibility: 'on' }],
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#ebf0f1' }, { visibility: 'on' }],
    },
    {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [{ color: '#ffffff' }, { lightness: 16 }],
    },
    {
        featureType: 'road.local',
        elementType: 'geometry.fill',
        stylers: [{ color: '#f8fafc' }, { visibility: 'on' }],
    },
    {
        featureType: 'road.local',
        elementType: 'geometry.stroke',
        stylers: [{ visibility: 'on' }, { color: '#ebf0f1' }],
    },
    {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [{ color: '#f2f2f2' }, { lightness: 19 }],
    },
    {
        featureType: 'transit.station.bus',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#e9e9e9' }, { lightness: 17 }],
    },
];
